import React, { useState, useEffect } from "react";
import FileForm from "./FileForm";
import {
  generateListOfWords,
  generateWordStatistics,
  updateWordKnownStatus,
} from "../utils/wordUtils";
import { FormatWordStatistics } from "./WordList";
import { updateWord, queryWordsBatch } from "../utils/dbUtils";
import { WordItem } from "../types";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import pdfToText from "react-pdftotext";
import { displayer } from "react-info-display";
import "./ParseFile.css";

interface ParseFileProps {
  onSelectedFile: (file: File | null) => void;
  onParsedText: (text: string) => void;
  inputText: string;
}

const ParseFile: React.FC<ParseFileProps> = ({
  onSelectedFile,
  onParsedText,
  inputText,
}) => {
  const [words, setWords] = useState<WordItem[]>([]);
  const [userUid, setUserUid] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user.uid);
      } else {
        displayer.add("User is logged out");
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleCheckboxChange = (word: string, knownStatus: boolean) => {
    updateWord(userUid, word, knownStatus);
  };

  const handleParsingResult = async (text: string) => {
    const wordsList = generateListOfWords(text);
    if (!wordsList) return;

    const wordStatistics = generateWordStatistics(wordsList);
    displayer.add(
      `There are ${Object.keys(wordStatistics).length} different words in this book.`
    );

    const sortedWordStatistics = Object.entries(wordStatistics).sort(
      (a, b) => b[1].count - a[1].count
    );

    const response = await queryWordsBatch(
      userUid,
      sortedWordStatistics.map(([word]) => `${word}`)
    );

    const words = updateWordKnownStatus(
      sortedWordStatistics,
      response.wordRecords
    );

    displayer.add(
      `There are ${response.totalCount} in your DB, ${response.knownCount} marked as known, ${response.unknownCount} marked as unknown.`
    );
    displayer.add(
      `There are ${response.knownCountInBook} known words in this book.`
    );

    setWords(words);
  };

  const handleFileSelection = (file: File) => {
    onSelectedFile(file);

    pdfToText(file)
      .then((text: string) => {
        onParsedText(text);
      })
      .catch((error: Error) =>
        displayer.add(`Failed to extract text from pdf: ${error.message}`)
      );
  };

  const handleClick = () => {
    handleParsingResult(inputText);
  };

  return (
    <div className="parse-file-container">
      <div className="file-form-section">
        <div className="parse-button-container">
          <button onClick={handleClick} className="parse-button">
            Parse the text
          </button>
        </div>
        <FileForm onFileSelect={handleFileSelection} />
      </div>
      <div className="output-section">
        <FormatWordStatistics
          wordStatistics={words}
          handleCheckboxChange={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default ParseFile;
