import React, { useEffect, useState } from "react";
import {
  User,
  getAuth,
  UserInfo,
  onAuthStateChanged,
  signOut,
  linkWithCredential,
  linkWithPopup,
  GoogleAuthProvider,
  EmailAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import MessageBox from "./MessageBox";

interface UserProfile {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  isAnonymours: boolean;
}

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const updateUserProfile = (user: User | null) => {
    if (user) {
      if (user.isAnonymous) {
        setUserInfo({
          providerId: user.providerId,
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          isAnonymours: user.isAnonymous,
        });
      } else {
        const providerData = user.providerData;
        if (providerData && providerData.length > 0) {
          const userProfile: UserInfo = providerData[0];
          setUserInfo({
            providerId: userProfile.providerId,
            uid: userProfile.uid,
            displayName: userProfile.displayName,
            email: userProfile.email,
            photoURL: userProfile.photoURL,
            isAnonymours: user.isAnonymous,
          });
        }
      }
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      updateUserProfile(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleBackToHome = () => {
    navigate("/home");
  };

  const handleEditProfile = () => {
    navigate("/edit-profile");
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const handleConvertWithEmail = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(email, password);
      linkWithCredential(user, credential)
        .then((userCredential) => {
          console.log(
            "Anonymous account successfully upgraded",
            userCredential.user
          );
          updateUserProfile(userCredential.user);
        })
        .catch(async (error) => {
          console.error("Error upgrading anonymous account:", error);
          await MessageBox.open({
            title: "Confirm",
            content: <p>Error upgrading anonymous account: {error.message}</p>,
            buttons: [{ name: "Got it", handler: () => "Got it" }],
          });
        });
    }
  };

  const handleConvertWithGoogle = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const provider = new GoogleAuthProvider();
      try {
        const result = await linkWithPopup(user, provider);
        console.log(
          "Anonymous account successfully linked with Google",
          result.user
        );
        updateUserProfile(result.user);
      } catch (error: any) {
        console.error("Error linking anonymous account with Google:", error);
        await MessageBox.open({
          title: "Confirm",
          content: (
            <p>Error linking anonymous account with Google: {error.message}</p>
          ),
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userInfo) {
    return <div>No user found.</div>;
  }

  return (
    <div className="profile-page">
      <h1 className="profile-heading">Profile</h1>
      <div className="profile-info">
        {userInfo.photoURL && (
          <img
            className="profile-image"
            src={userInfo.photoURL}
            alt="Profile"
          />
        )}
        <p className="profile-text">
          <strong>Name:</strong> {userInfo.displayName}
        </p>
        <p className="profile-text">
          <strong>Email:</strong> {userInfo.email}
        </p>
        <p className="profile-text">
          <strong>Provider:</strong> {userInfo.providerId}
        </p>
        <p className="profile-text">
          <strong>UID:</strong> {userInfo.uid}
        </p>
        {userInfo.isAnonymours && (
          <div className="anonymous-section">
            <p className="profile-note">
              <strong>Note:</strong> You are signed in anonymously.
            </p>
            <div className="convert-section">
              <input
                className="convert-input"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className="convert-input"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="convert-button"
                onClick={handleConvertWithEmail}
              >
                Convert with Email
              </button>
              <button
                className="convert-button"
                onClick={handleConvertWithGoogle}
              >
                Convert with Google
              </button>
            </div>
          </div>
        )}
        <div className="profile-buttons">
          <button className="profile-button" onClick={handleEditProfile}>
            Edit Profile
          </button>
          <button className="profile-button" onClick={handleBackToHome}>
            Back to Home
          </button>
          <button className="profile-button" onClick={handleSignOut}>
            Sign Out
          </button>
        </div>
      </div>
      <div id="app-message-box" />
    </div>
  );
};

export default Profile;
