import "./WordList.css";
import React, { useState } from "react";
import { WordItem } from "../types";
import DictionaryListWindow from "./DictionaryListWindow";

interface WordRowProps {
  word: string;
  count: number;
  known: number;
  index: number;
  handleCheckboxChange: ((word: string, knownStatus: boolean) => void) | null;
  showUnknownOnly: boolean;
}

const WordRow: React.FC<WordRowProps> = ({
  word,
  count,
  known,
  index,
  handleCheckboxChange,
  showUnknownOnly,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(known === 1);

  const handleCheckboxChangeInternal = () => {
    setIsChecked(!isChecked);
    if (handleCheckboxChange) {
      handleCheckboxChange(word, !isChecked);
    }
  };

  const knownStatus = isChecked ? "Yes" : "No";

  if (showUnknownOnly && isChecked) {
    return null;
  } else {
    return (
      <tr>
        <td>{index}</td>
        <td>
          <DictionaryListWindow word={word} />
        </td>
        <td>{count}</td>
        <td>
          <label>
            <input
              id={word}
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChangeInternal}
            />
            {knownStatus}
          </label>
        </td>
      </tr>
    );
  }
};

const FormatWordStatistics: React.FC<{
  wordStatistics: WordItem[];
  handleCheckboxChange: ((word: string, knownStatus: boolean) => void) | null;
}> = ({ wordStatistics, handleCheckboxChange }) => {
  const [showUnknownOnly, setShowUnknownOnly] = useState(false);

  const handleShowUnknownOnlyChange = () => {
    setShowUnknownOnly(!showUnknownOnly);
  };

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={showUnknownOnly}
          onChange={handleShowUnknownOnlyChange}
        />
        Show unknown words only
      </label>

      <div className="grid">
        <table>
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Word</th>
              <th scope="col">Count</th>
              <th scope="col">Known</th>
            </tr>
          </thead>
          <tbody>
            {wordStatistics.map(([word, { count, origin, known }], index) => (
              <WordRow
                key={word}
                word={origin}
                count={count}
                known={known ?? 0}
                index={index}
                handleCheckboxChange={handleCheckboxChange}
                showUnknownOnly={showUnknownOnly}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { FormatWordStatistics };
