import "./AppContent.css";
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TitleBar from "./components/TitleBar";
import ParseFile from "./components/ParseFile";
import { useNavigate } from "react-router-dom";
import { InfoDisplay, displayer } from "react-info-display";

const AppContent = () => {
  const navigate = useNavigate();
  const [inputText, setInputText] = useState<string>("");
  const [consoleLogs, setConsoleLogs] = useState<string[]>([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        displayer.add(`User signed in with UID: ${uid}`);
      } else {
        displayer.add("User is logged out");
        navigate("/");
      }
    });

    const logCallback = (logs: string[]) => setConsoleLogs([...logs]);
    displayer.subscribe(logCallback);

    return () => {
      unsubscribe();
      displayer.unsubscribe(logCallback);
    };
  }, [navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const handleSelectedFileInfo = (file: File | null) => {
    if (file) {
      const fileInfo = `Selected file: ${file.name}, Size: ${file.size} bytes, Type: ${file.type}`;
      displayer.add(fileInfo);
    }
  };

  const handleParsedText = (text: string) => {
    setInputText(text);
  };

  return (
    <div className="container">
      <TitleBar />
      <div className="content">
        <div className="left-half">
          <textarea
            value={inputText}
            onChange={handleInputChange}
            placeholder="Paste text here or see parsed content..."
          />
        </div>
        <div className="right-half">
          <ParseFile
            onSelectedFile={handleSelectedFileInfo}
            onParsedText={handleParsedText}
            inputText={inputText}
          />
        </div>
      </div>
      <InfoDisplay messages={consoleLogs} size={{ height: "100px" }} />
    </div>
  );
};

export default AppContent;
