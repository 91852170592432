import "./Signin.css";
import React, { useState, FormEvent } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  signInAnonymously,
} from "firebase/auth";
import { auth } from "../firebase";
import { NavLink, useNavigate } from "react-router-dom";
import MessageBox from "../components/MessageBox";

const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSignin = (e: FormEvent) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        if (!user.emailVerified) {
          await MessageBox.open({
            title: "Confirm",
            content: <p>Please verify your email before signing in.</p>,
            buttons: [
              {
                name: "Got it",
                handler: () => {
                  signOut(auth)
                    .then(() => {
                      navigate("/");
                      console.log("Signed out successfully");
                    })
                    .catch((error) => {
                      // An error happened.
                    });
                },
              },
            ],
          });

          return;
        } else {
          navigate("/home");
          console.log(user);
        }
      })
      .catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);

        const result = await MessageBox.open({
          title: "Confirm",
          content: <p>{errorMessage}</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
        console.log(result);
      });
  };

  const onForgotPassword = async () => {
    if (!email) {
      await MessageBox.open({
        title: "Error",
        content: <p>Please enter your email address first.</p>,
        buttons: [{ name: "Got it", handler: () => "Got it" }],
      });
      return;
    }

    sendPasswordResetEmail(auth, email)
      .then(async () => {
        await MessageBox.open({
          title: "Success",
          content: <p>Password reset email sent successfully.</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
      })
      .catch(async (error) => {
        await MessageBox.open({
          title: "Error",
          content: <p>{error.message}</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
      });
  };

  const onGoogleSignin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        console.log(token);
        // The signed-in user info.
        const user = result.user;
        navigate("/home");
        console.log(user);
      })
      .catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);

        await MessageBox.open({
          title: "Error",
          content: <p>{errorMessage}</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
      });
  };

  const onAnonymousSignin = () => {
    signInAnonymously(auth)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/home");
        console.log(user);
      })
      .catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);

        await MessageBox.open({
          title: "Error",
          content: <p>{errorMessage}</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
      });
  };

  return (
    <main>
      <section>
        <div className="signin-form">
          <h2> Vocab Ahead </h2>

          <form>
            <div>
              <label htmlFor="email-address">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </div>

            <div>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                required
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <button onClick={onSignin}>Login</button>
            </div>
          </form>

          <div>
            <button className="google-signin-button" onClick={onGoogleSignin}>
              <img
                src="https://developers.google.com/identity/images/g-logo.png"
                alt="Google logo"
              />
              <span className="google-signin-text">Sign in with Google</span>
            </button>
          </div>

          <div>
            <button
              className="anonymous-signin-button"
              onClick={onAnonymousSignin}
            >
              Sign in anonymously
            </button>
          </div>

          <p className="text-sm text-white text-center">
            <NavLink
              to="#"
              onClick={onForgotPassword}
              className="forgot-password-link"
            >
              Forgotten your password?
            </NavLink>
          </p>

          <p className="text-sm text-white text-center">
            No account yet? <NavLink to="/signup">Sign up</NavLink>
          </p>
        </div>
        <div id="app-message-box" />
      </section>
    </main>
  );
};

export default Signin;
