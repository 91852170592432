import React, { ReactNode } from "react";
import "./modal.scss";

interface ModalProps {
  title?: string;
  onClose?: () => void;
  children?: ReactNode;
}

const defaultProps: ModalProps = {
  title: "ModalTitle",
  onClose: () => {},
};

export default function Modal(props: ModalProps) {
  const { title, onClose, children } = { ...defaultProps, ...props };
  return (
    <div className="modal__backdrop">
      <div className="modal__wrapper">
        <div className="modal__header">
          <h2>{title}</h2>
          <div className="modal__close" onClick={onClose}>
            &times;
          </div>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
}
