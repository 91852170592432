import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./WelcomePage.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const WelcomePage: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log("uid", uid);
        navigate("/home");
      } else {
        console.log("user is logged out");
      }
    });
  }, [navigate]);

  return (
    <div className="welcome-page">
      <header className="simple-header">
        <div className="header-left">Vocab Ahead</div>
        <div className="header-right">
          <button className="menu-button" onClick={toggleMenu}>
            ...
          </button>
          {menuOpen && (
            <div className="menu">
              <Link to="/signin" className="menu-link">
                Sign In
              </Link>
              <Link to="/signup" className="menu-link">
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </header>

      <div className="main-content">
        <header className="welcome-header">
          <h1>Welcome to Vocab Ahead</h1>
          <p>Your companion in enhancing English vocabulary</p>
        </header>

        <section className="features-section">
          <h2>Features</h2>
          <ul>
            <li>
              <strong>Upload PDF Books:</strong> Easily upload any PDF book and
              let Vocab-Ahead extract the words for you.
            </li>
            <li>
              <strong>Mark Unknown Words:</strong> Highlight unfamiliar words as
              you read. These words will be saved for you to review later.
            </li>
            <li>
              <strong>Personalized Word Lists:</strong> Your marked words are
              stored in a personal database, allowing you to build a customized
              vocabulary list.
            </li>
            <li>
              <strong>Review and Study:</strong> Access your saved words anytime
              to review and reinforce your learning.
            </li>
            <li>
              <strong>Statistics:</strong> Track your progress with detailed
              statistics on the words you've marked and learned.
            </li>
            <li>
              <strong>Interactive Quizzes (Coming Soon):</strong> Test your
              knowledge with interactive quizzes based on your marked words.
            </li>
          </ul>
        </section>

        <section className="how-it-works-section">
          <h2>How It Works</h2>
          <div className="how-it-works-steps">
            <div className="step">
              <h3>Step 1: Sign Up / Sign In</h3>
              <p>Create an account or log in to start using Vocab-Ahead.</p>
            </div>
            <div className="step">
              <h3>Step 2: Upload Your Book</h3>
              <p>Upload any PDF book to begin extracting and marking words.</p>
            </div>
            <div className="step">
              <h3>Step 3: Mark Unknown Words</h3>
              <p>
                As you read, highlight any words you don't know. These will be
                saved in your personal word list.
              </p>
            </div>
            <div className="step">
              <h3>Step 4: Review and Learn</h3>
              <p>
                Access your marked words at any time to review and study them.
              </p>
            </div>
            <div className="step">
              <h3>Step 5: Track Your Progress</h3>
              <p>
                Use our statistics feature to see how much you've learned and
                improve your vocabulary over time.
              </p>
            </div>
          </div>
        </section>

        <section className="get-started-section">
          <h2>Get Started</h2>
          <p>Sign up today to start improving your vocabulary.</p>
          <div className="buttons">
            <Link to="/signup" className="button signup-button">
              Sign Up
            </Link>
            <Link to="/signin" className="button signin-button">
              Sign In
            </Link>
          </div>
        </section>
      </div>

      <footer className="welcome-footer">
        <div className="footer-left">
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link to="/terms" className="footer-link">
            Terms and Conditions
          </Link>
        </div>
        <div className="footer-right">
          <span className="footer-copyright">
            Copyright &copy; 2024 Vocab-Ahead. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default WelcomePage;
