import React, { useState } from "react";
import {
  getAuth,
  verifyBeforeUpdateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateProfile,
  deleteUser,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./EditProfile.css";
import { deleteUser as deleteUserFromDB } from "../utils/dbUtils";
import MessageBox from "./MessageBox";

const EditProfile: React.FC = () => {
  const [newEmail, setNewEmail] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleSendVerification = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && newEmail && password) {
      const credential = EmailAuthProvider.credential(user.email!, password);

      reauthenticateWithCredential(user, credential)
        .then(() => {
          verifyBeforeUpdateEmail(user, newEmail)
            .then(() => {
              setMessage(
                "Email updated successfully! Please verify your new email."
              );
            })
            .catch((error) => {
              setMessage(`Error updating email: ${error.message}`);
            });
        })
        .catch((error) => {
          setMessage(`Error reauthenticating: ${error.message}`);
        });
    } else {
      setMessage("Please enter your new email and current password.");
    }
  };

  const handleNameUpdate = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user && newName) {
      updateProfile(user, { displayName: newName })
        .then(() => {
          setMessage("Name updated successfully!");
        })
        .catch((error) => {
          setMessage(`Error updating name: ${error.message}`);
        });
    } else {
      setMessage("Please enter a new name.");
    }
  };

  const handleDeleteAccount = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!!user) {
      await deleteUserFromDB(user);

      deleteUser(user)
        .then(async () => {
          const result = await MessageBox.open({
            title: "Confirm",
            content: <p>User deleted.</p>,
            buttons: [{ name: "Got it", handler: () => "Got it" }],
          });
          console.log(result);
          navigate("/");
        })
        .catch(async (error) => {
          const errorMessage = error.message;
          const result = await MessageBox.open({
            title: "Confirm",
            content: <p>{errorMessage}</p>,
            buttons: [{ name: "Got it", handler: () => "Got it" }],
          });
          console.log(result);
        });
    }
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  return (
    <div className="edit-profile-page">
      <div className="edit-profile">
        <h2>Edit Profile</h2>
        <label>
          Name:
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="New name"
            className="name-input"
          />
        </label>
        <button onClick={handleNameUpdate} className="update-button">
          Update Name
        </button>
        <h2>Update Email</h2>
        <p>Please enter your new email address and your current password:</p>
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="New email address"
          className="email-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Current password"
          className="password-input"
        />
        <button onClick={handleSendVerification} className="update-button">
          Update Email
        </button>
        <button onClick={handleDeleteAccount} className="delete-button">
          Delete Account
        </button>
        <button onClick={handleCancel} className="cancel-button">
          Cancel
        </button>
        {message && <p className="message">{message}</p>}
        <div id="app-message-box" />
      </div>
    </div>
  );
};

export default EditProfile;
