import { User } from "firebase/auth";
import { RequestBody } from "../../functions/insert/user";
import { query_batch_request_body } from "../../functions/query/batch";
import { delete_user_request_body } from "../../functions/delete/user";

// Updates a word record to be known or unkown
const updateWord = async (user_uid: string, word: string, known: boolean) => {
  try {
    const response = await fetch(
      `/update/${user_uid}/${word}/${Number(known)}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    console.log(jsonData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const updateWordsBatch = async (username: string, words: string[]) => {
  try {
    const encodedUsername = encodeURIComponent(username);

    // Prepare the data to be sent in the request body
    const requestBody = {
      username: encodedUsername,
      words: words,
    };

    const response = await fetch(`/update/batch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    console.log(jsonData);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchData = async (username: string) => {
  try {
    const encodedUsername = encodeURIComponent(username);
    const response = await fetch(`/query/${encodedUsername}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return "";
  }
};

/**
 * Finds the intersection of the provided words list and the words from the database with known status.
 * @param username The username of the user.
 * @param wordsList The list of words to intersect with the database.
 * @returns The intersection of the words list and the words from the database with known status.
 */
const queryWordsBatch = async (user_uid: string, words: string[]) => {
  try {
    // Prepare the data to be sent in the request body
    const requestBody: query_batch_request_body = {
      user_uid: user_uid,
      words: words,
    };

    const response = await fetch(`/query/batch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return "";
  }
};

// Inserts a user into the Users table
const insertUser = async (user: User) => {
  try {
    const requestBody: RequestBody = {
      username: user.displayName ? user.displayName : "",
      email: user.email ? user.email : "",
      UUID: user.uid,
    };

    const response = await fetch(`/insert/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error inserting user:", error);
    return "";
  }
};

// Deletes a user from the Users table
const deleteUser = async (user: User) => {
  try {
    const requestBody: delete_user_request_body = {
      user_uid: user.uid,
    };

    const response = await fetch(`/delete/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error deleting user:", error);
    return "";
  }
};

export {
  fetchData,
  updateWord,
  updateWordsBatch,
  queryWordsBatch,
  insertUser,
  deleteUser,
};
