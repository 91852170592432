import React, { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import Modal from "./Modal";

interface Button {
  name: string;
  handler: () => any;
}

interface ModalProps {
  title?: string;
  content?: ReactNode;
  buttons: Button[];
}

const defaultProps = {
  title: "MessageBox",
  content: <></>,
  container: "#app-message-box",
  buttons: [],
};

const MessageBox = {
  open: (props: ModalProps) => {
    return new Promise((resolve) => {
      const { title, content, container, buttons } = {
        ...defaultProps,
        ...props,
      };
      const containerElement = document.querySelector(container);
      if (!containerElement) throw Error(`can't find container ${container}`);

      const handleClose = (value?: any) => {
        let result = null;
        if (value && !value.target) {
          result = value;
        }
        root.unmount();
        return resolve(result);
      };

      const handleButton = (handler: () => any) => () => {
        handleClose(handler());
      };

      const root = ReactDOM.createRoot(containerElement);
      root.render(
        <Modal title={title} onClose={handleClose}>
          {content}
          {buttons.map((btn) => {
            return (
              <button onClick={handleButton(btn.handler)} key={btn.name}>
                {btn.name}
              </button>
            );
          })}
        </Modal>,
      );
    });
  },
};

export default MessageBox;
