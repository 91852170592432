import React from "react";
import "./FileForm.css";

interface FileFormProps {
  onFileSelect: (file: File) => void;
}

const FileForm: React.FC<FileFormProps> = ({ onFileSelect }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    onFileSelect(file);
  };

  return (
    <div className="file-form">
      <label htmlFor="file-upload" className="file-button">
        Import a PDF file
      </label>
      <input
        id="file-upload"
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FileForm;
