import React, { useState } from "react";
import "./TitleBar.css";
import UserMenu from "./UserMenu";

const TitleBar = () => {
  const [showUserMenu, setShowUserMenu] = useState(false);

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  return (
    <div className="title-bar">
      <h1 className="title">Vocab Ahead</h1>
      <div className="user-menu-container">
        <button className="user-account-icon" onClick={toggleUserMenu}></button>
        {showUserMenu && <UserMenu />}
      </div>
    </div>
  );
};

export default TitleBar;
