import React from "react";
import "./UserMenu.css";
import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../firebase";

const UserMenu = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <div className="user-menu">
      <ul>
        <li>
          <button className="menu-item" onClick={handleProfile}>
            Profile
          </button>
        </li>

        <li>
          <button className="menu-item" onClick={handleLogout}>
            Log out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
