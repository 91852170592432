import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <header className="privacy-header">
        <h1>Privacy Policy</h1>
      </header>
      <section className="privacy-content">
        <p>
          Your privacy is important to us. Here’s what we collect and how we
          handle your data:
        </p>
        <ul>
          <li>
            <strong>Email:</strong> We collect your email address when you sign
            up.
          </li>
          <li>
            <strong>File Processing:</strong> The files you input are processed
            on your device and are not uploaded to our servers.
          </li>
          <li>
            <strong>Data Storage:</strong> Only the words extracted from your
            books and the known status of these words marked by you are saved in
            our cloud database.
          </li>
        </ul>
        <p>
          By signing up, you agree to our Privacy Policy and Terms and
          Conditions. If there are any changes to our policy, we will update it
          accordingly.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
