import "./App.css";
import React from "react";
import Signup from "./auth/Signup";
import Signin from "./auth/Signin";
import AppContent from "./AppContent";
import WelcomePage from "./components/WelcomePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";

function App() {
  return (
    <Router>
      <div>
        <section>
          <Routes>
            <Route path="/home" element={<AppContent />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/" element={<WelcomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
          </Routes>
        </section>
      </div>
    </Router>
  );
}

export default App;
