import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div className="terms">
      <header className="terms-header">
        <h1>Terms and Conditions</h1>
      </header>
      <section className="terms-content">
        <p>
          By using Vocab-Ahead, you agree to the following terms and conditions:
        </p>
        <ul>
          <li>
            You must provide accurate and complete information during the
            sign-up process.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your
            account information.
          </li>
          <li>
            Any data you upload or mark is your responsibility, and you ensure
            it does not violate any laws.
          </li>
          <li>
            We reserve the right to modify or terminate the service for any
            reason, without notice, at any time.
          </li>
          <li>
            We reserve the right to alter these Terms and Conditions at any
            time. Continued use of the service will constitute your acceptance
            of any changes.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Terms;
