import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

interface DictProps {
  word: string;
}

const DictionaryListWindow: React.FC<DictProps> = ({ word }) => {
  return (
    <Popup
      trigger={<label> {word} </label>}
      position="right center"
      contentStyle={{
        width: "auto",
        padding: "20px",
        zIndex: 10000,
        lineHeight: 1.5,
      }}
    >
      <ul>
        <li>
          <a
            href={`https://www.merriam-webster.com/dictionary/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Merriam-Webster
          </a>
        </li>
        <li>
          <a
            href={`https://www.oxfordlearnersdictionaries.com/definition/english/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Oxford Learner’s Dictionaries
          </a>
        </li>
        <li>
          <a
            href={`https://dictionary.cambridge.org/dictionary/english/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cambridge Dictionary
          </a>
        </li>
        <li>
          <a
            href={`https://www.wordreference.com/definition/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            WordReference
          </a>
        </li>
        <li>
          <a
            href={`https://www.dictionary.com/browse/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Dictionary.com
          </a>
        </li>
        <li>
          <a
            href={`https://www.thesaurus.com/browse/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Thesaurus.com
          </a>
        </li>

        <li>
          <a
            href={`https://www.collinsdictionary.com/zh/dictionary/english-chinese/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Collins
          </a>
        </li>

        <li>
          <a
            href={`https://www.urbandictionary.com/define.php?term=${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            urbandictionary
          </a>
        </li>

        <li>
          <a
            href={`https://dict.youdao.com/w/eng/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            有道词典
          </a>
        </li>

        <li>
          <a
            href={`https://www.iciba.com/word?w=${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            金山词霸
          </a>
        </li>

        <li>
          <a
            href={`http://dict.eudic.net/dicts/en/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            欧路词典
          </a>
        </li>

        <li>
          <a
            href={`https://dictionary.cambridge.org/zhs/%E8%AF%8D%E5%85%B8/%E8%8B%B1%E8%AF%AD-%E6%B1%89%E8%AF%AD-%E7%AE%80%E4%BD%93/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            剑桥词典（英汉）
          </a>
        </li>

        <li>
          <a
            href={`https://dict.hjenglish.com/w/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            沪江小D
          </a>
        </li>

        <li>
          <a
            href={`https://dict.cn/${word}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            海词词典
          </a>
        </li>
      </ul>
    </Popup>
  );
};

export default DictionaryListWindow;
