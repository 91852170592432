import "./Signup.css";
import React, { useState, FormEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";
import { insertUser } from "../utils/dbUtils";
import MessageBox from "../components/MessageBox";

const Signup = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        await insertUser(user);

        sendEmailVerification(user).then(async () => {
          await MessageBox.open({
            title: "Confirm",
            content: (
              <p>
                Email verification sent. Please check the verification email in
                your email box.
              </p>
            ),
            buttons: [{ name: "Got it", handler: () => "Got it" }],
          });
          console.log("Email verification sent!");
        });

        navigate("/signin");
      })
      .catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        const result = await MessageBox.open({
          title: "Confirm",
          content: <p>{errorMessage}</p>,
          buttons: [{ name: "Got it", handler: () => "Got it" }],
        });
        console.log(result);
      });
  };

  return (
    <main>
      <section>
        <div className="signup-form">
          <div>
            <h2> Vocab Ahead </h2>
            <form>
              <div>
                <label htmlFor="email-address">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email address"
                  autoComplete="off"
                />
              </div>

              <div>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Password"
                />
              </div>

              <button type="submit" onClick={onSubmit}>
                Sign up
              </button>
            </form>

            <p className="text-sm text-white text-center">
              Already have an account? <NavLink to="/signin">Sign in</NavLink>
            </p>
          </div>
          <div id="app-message-box" />
        </div>
      </section>
    </main>
  );
};

export default Signup;
